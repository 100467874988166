import { render, staticRenderFns } from "./FlowParticlesProject.vue?vue&type=template&id=7222e884&scoped=true&"
import script from "./FlowParticlesProject.vue?vue&type=script&lang=js&"
export * from "./FlowParticlesProject.vue?vue&type=script&lang=js&"
import style0 from "./FlowParticlesProject.vue?vue&type=style&index=0&id=7222e884&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7222e884",
  null
  
)

export default component.exports