<template>
    <div class="project">
        <h1>Flow Particles</h1>
        <div class="topInfo">
            <p>
                When: 2015<br />
                Experiment with the Intel Realsense Depth camera and optical
                flow.<br />
                While working at
                <a href="https://wearebarbarian.com/">Barbarian Group</a>
            </p>
        </div>
        <div class="media">
            <b-embed
                class="media"
                type="iframe"
                aspect="16by9"
                allowfullscreen
                src="https://player.vimeo.com/video/146994078"
            ></b-embed>
        </div>
        <div class="content">
            <p>
                GPU particle system that uses a simple physics model and
                OpenCV's optical flow (Farneback) to add acceleration to the
                particles. The birth positions come from sampling a depth
                camera, and although the color buffer is used for calculating
                optical flow, I still lookup the next position (derived from the
                flow field) in 3D, which is why some particles are flying at the
                camera eye. The flow field is smoothed using a convolution
                filter, sort of a fake 2d fluid sim (we were targeting low end
                devices and needed to slim down the processing needs, so while
                something like Navier–Stokes would have probably looked better
                our approach was dirt cheap).
            </p>
            <p>
                Prototyped while doing research for a project I worked on in
                2015 at The Barbarian Group, although we ended up not using this
                approach in the final concept. Built with <a href="https://libcinder.org/">libcinder</a>.
            </p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../assets/projects/style.scss";
</style>